const isDynamicImportError = (error: Error) => {
  const errorRepresentation = `${error.name}: ${error.message}`;

  return (
    /.*typeerror.*module.*/gi.test(errorRepresentation) ||
    /.*text\/html.*mime.*type.*/gi.test(errorRepresentation)
  );
};

export default isDynamicImportError;
