import 'core-js/features/array/at';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from '../src/app';
import { AppProvider } from './AppProvider';
import './index.css';
import { Environment, getEnvironment } from './utils/env';
import { PikToaster } from './base_components/PikToaster/PikToaster';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { isCobblestoneError } from './errors/CobblestoneError';
import { AxiosError } from 'axios';

const SENTRY_DSN = 'https://00a7d3a4a8a0d07925a82b71267ac3b5@us.sentry.io/4506695034339328';

if (
  (getEnvironment() === Environment.PROD || getEnvironment() === Environment.QA) &&
  !navigator.userAgent.endsWith('DatadogSynthetics') &&
  !navigator.userAgent.includes('CBPDFExporter')
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    tunnel: '/tunnel/sentry',
    environment: getEnvironment(),
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, { originalException }) => {
      if (
        (originalException && isCobblestoneError(originalException)) ||
        (originalException instanceof AxiosError &&
          originalException.response !== undefined &&
          originalException.response.status === 500)
      ) {
        return null;
      }

      return event;
    },
  });
}

const element = document.querySelector('#root');
if (element != null) {
  const root = createRoot(element);

  root.render(
    <>
      <PikToaster />
      <AppProvider>
        <App />
      </AppProvider>
    </>
  );
}

// Preloaded tailwind CSS classes ---------------------------------------------
// All possible width percentages
// 'w-[0%]'
// 'w-[1%]'
// 'w-[2%]'
// 'w-[3%]'
// 'w-[4%]'
// 'w-[5%]'
// 'w-[6%]'
// 'w-[7%]'
// 'w-[8%]'
// 'w-[9%]'
// 'w-[10%]'
// 'w-[11%]'
// 'w-[12%]'
// 'w-[13%]'
// 'w-[14%]'
// 'w-[15%]'
// 'w-[16%]'
// 'w-[17%]'
// 'w-[18%]'
// 'w-[19%]'
// 'w-[20%]'
// 'w-[21%]'
// 'w-[22%]'
// 'w-[23%]'
// 'w-[24%]'
// 'w-[25%]'
// 'w-[26%]'
// 'w-[27%]'
// 'w-[28%]'
// 'w-[29%]'
// 'w-[30%]'
// 'w-[31%]'
// 'w-[32%]'
// 'w-[33%]'
// 'w-[34%]'
// 'w-[35%]'
// 'w-[36%]'
// 'w-[37%]'
// 'w-[38%]'
// 'w-[39%]'
// 'w-[40%]'
// 'w-[41%]'
// 'w-[42%]'
// 'w-[43%]'
// 'w-[44%]'
// 'w-[45%]'
// 'w-[46%]'
// 'w-[47%]'
// 'w-[48%]'
// 'w-[49%]'
// 'w-[50%]'
// 'w-[51%]'
// 'w-[52%]'
// 'w-[53%]'
// 'w-[54%]'
// 'w-[55%]'
// 'w-[56%]'
// 'w-[57%]'
// 'w-[58%]'
// 'w-[59%]'
// 'w-[60%]'
// 'w-[61%]'
// 'w-[62%]'
// 'w-[63%]'
// 'w-[64%]'
// 'w-[65%]'
// 'w-[66%]'
// 'w-[67%]'
// 'w-[68%]'
// 'w-[69%]'
// 'w-[70%]'
// 'w-[71%]'
// 'w-[72%]'
// 'w-[73%]'
// 'w-[74%]'
// 'w-[75%]'
// 'w-[76%]'
// 'w-[77%]'
// 'w-[78%]'
// 'w-[79%]'
// 'w-[80%]'
// 'w-[81%]'
// 'w-[82%]'
// 'w-[83%]'
// 'w-[84%]'
// 'w-[85%]'
// 'w-[86%]'
// 'w-[87%]'
// 'w-[88%]'
// 'w-[89%]'
// 'w-[90%]'
// 'w-[91%]'
// 'w-[92%]'
// 'w-[93%]'
// 'w-[94%]'
// 'w-[95%]'
// 'w-[96%]'
// 'w-[97%]'
// 'w-[98%]'
// 'w-[99%]'
// 'w-[100%]'

// All possible height percentages
// 'h-[0%]'
// 'h-[1%]'
// 'h-[2%]'
// 'h-[3%]'
// 'h-[4%]'
// 'h-[5%]'
// 'h-[6%]'
// 'h-[7%]'
// 'h-[8%]'
// 'h-[9%]'
// 'h-[10%]'
// 'h-[11%]'
// 'h-[12%]'
// 'h-[13%]'
// 'h-[14%]'
// 'h-[15%]'
// 'h-[16%]'
// 'h-[17%]'
// 'h-[18%]'
// 'h-[19%]'
// 'h-[20%]'
// 'h-[21%]'
// 'h-[22%]'
// 'h-[23%]'
// 'h-[24%]'
// 'h-[25%]'
// 'h-[26%]'
// 'h-[27%]'
// 'h-[28%]'
// 'h-[29%]'
// 'h-[30%]'
// 'h-[31%]'
// 'h-[32%]'
// 'h-[33%]'
// 'h-[34%]'
// 'h-[35%]'
// 'h-[36%]'
// 'h-[37%]'
// 'h-[38%]'
// 'h-[39%]'
// 'h-[40%]'
// 'h-[41%]'
// 'h-[42%]'
// 'h-[43%]'
// 'h-[44%]'
// 'h-[45%]'
// 'h-[46%]'
// 'h-[47%]'
// 'h-[48%]'
// 'h-[49%]'
// 'h-[50%]'
// 'h-[51%]'
// 'h-[52%]'
// 'h-[53%]'
// 'h-[54%]'
// 'h-[55%]'
// 'h-[56%]'
// 'h-[57%]'
// 'h-[58%]'
// 'h-[59%]'
// 'h-[60%]'
// 'h-[61%]'
// 'h-[62%]'
// 'h-[63%]'
// 'h-[64%]'
// 'h-[65%]'
// 'h-[66%]'
// 'h-[67%]'
// 'h-[68%]'
// 'h-[69%]'
// 'h-[70%]'
// 'h-[71%]'
// 'h-[72%]'
// 'h-[73%]'
// 'h-[74%]'
// 'h-[75%]'
// 'h-[76%]'
// 'h-[77%]'
// 'h-[78%]'
// 'h-[79%]'
// 'h-[80%]'
// 'h-[81%]'
// 'h-[82%]'
// 'h-[83%]'
// 'h-[84%]'
// 'h-[85%]'
// 'h-[86%]'
// 'h-[87%]'
// 'h-[88%]'
// 'h-[89%]'
// 'h-[90%]'
// 'h-[91%]'
// 'h-[92%]'
// 'h-[93%]'
// 'h-[94%]'
// 'h-[95%]'
// 'h-[96%]'
// 'h-[97%]'
// 'h-[98%]'
// 'h-[99%]'
// 'h-[100%]'

// 'rotate-[0deg]'
// 'rotate-[1deg]'
// 'rotate-[2deg]'
// 'rotate-[3deg]'
// 'rotate-[4deg]'
// 'rotate-[5deg]'
// 'rotate-[6deg]'
// 'rotate-[7deg]'
// 'rotate-[8deg]'
// 'rotate-[9deg]'
// 'rotate-[10deg]'
// 'rotate-[11deg]'
// 'rotate-[12deg]'
// 'rotate-[13deg]'
// 'rotate-[14deg]'
// 'rotate-[15deg]'
// 'rotate-[16deg]'
// 'rotate-[17deg]'
// 'rotate-[18deg]'
// 'rotate-[19deg]'
// 'rotate-[20deg]'
// 'rotate-[21deg]'
// 'rotate-[22deg]'
// 'rotate-[23deg]'
// 'rotate-[24deg]'
// 'rotate-[25deg]'
// 'rotate-[26deg]'
// 'rotate-[27deg]'
// 'rotate-[28deg]'
// 'rotate-[29deg]'
// 'rotate-[30deg]'
// 'rotate-[31deg]'
// 'rotate-[32deg]'
// 'rotate-[33deg]'
// 'rotate-[34deg]'
// 'rotate-[35deg]'
// 'rotate-[36deg]'
// 'rotate-[37deg]'
// 'rotate-[38deg]'
// 'rotate-[39deg]'
// 'rotate-[40deg]'
// 'rotate-[41deg]'
// 'rotate-[42deg]'
// 'rotate-[43deg]'
// 'rotate-[44deg]'
// 'rotate-[45deg]'
// 'rotate-[46deg]'
// 'rotate-[47deg]'
// 'rotate-[48deg]'
// 'rotate-[49deg]'
// 'rotate-[50deg]'
// 'rotate-[51deg]'
// 'rotate-[52deg]'
// 'rotate-[53deg]'
// 'rotate-[54deg]'
// 'rotate-[55deg]'
// 'rotate-[56deg]'
// 'rotate-[57deg]'
// 'rotate-[58deg]'
// 'rotate-[59deg]'
// 'rotate-[60deg]'
// 'rotate-[61deg]'
// 'rotate-[62deg]'
// 'rotate-[63deg]'
// 'rotate-[64deg]'
// 'rotate-[65deg]'
// 'rotate-[66deg]'
// 'rotate-[67deg]'
// 'rotate-[68deg]'
// 'rotate-[69deg]'
// 'rotate-[70deg]'
// 'rotate-[71deg]'
// 'rotate-[72deg]'
// 'rotate-[73deg]'
// 'rotate-[74deg]'
// 'rotate-[75deg]'
// 'rotate-[76deg]'
// 'rotate-[77deg]'
// 'rotate-[78deg]'
// 'rotate-[79deg]'
// 'rotate-[80deg]'
// 'rotate-[81deg]'
// 'rotate-[82deg]'
// 'rotate-[83deg]'
// 'rotate-[84deg]'
// 'rotate-[85deg]'
// 'rotate-[86deg]'
// 'rotate-[87deg]'
// 'rotate-[88deg]'
// 'rotate-[89deg]'
// 'rotate-[90deg]'
// 'rotate-[91deg]'
// 'rotate-[92deg]'
// 'rotate-[93deg]'
// 'rotate-[94deg]'
// 'rotate-[95deg]'
// 'rotate-[96deg]'
// 'rotate-[97deg]'
// 'rotate-[98deg]'
// 'rotate-[99deg]'
// 'rotate-[100deg]'
// 'rotate-[101deg]'
// 'rotate-[102deg]'
// 'rotate-[103deg]'
// 'rotate-[104deg]'
// 'rotate-[105deg]'
// 'rotate-[106deg]'
// 'rotate-[107deg]'
// 'rotate-[108deg]'
// 'rotate-[109deg]'
// 'rotate-[110deg]'
// 'rotate-[111deg]'
// 'rotate-[112deg]'
// 'rotate-[113deg]'
// 'rotate-[114deg]'
// 'rotate-[115deg]'
// 'rotate-[116deg]'
// 'rotate-[117deg]'
// 'rotate-[118deg]'
// 'rotate-[119deg]'
// 'rotate-[120deg]'
// 'rotate-[121deg]'
// 'rotate-[122deg]'
// 'rotate-[123deg]'
// 'rotate-[124deg]'
// 'rotate-[125deg]'
// 'rotate-[126deg]'
// 'rotate-[127deg]'
// 'rotate-[128deg]'
// 'rotate-[129deg]'
// 'rotate-[130deg]'
// 'rotate-[131deg]'
// 'rotate-[132deg]'
// 'rotate-[133deg]'
// 'rotate-[134deg]'
// 'rotate-[135deg]'
// 'rotate-[136deg]'
// 'rotate-[137deg]'
// 'rotate-[138deg]'
// 'rotate-[139deg]'
// 'rotate-[140deg]'
// 'rotate-[141deg]'
// 'rotate-[142deg]'
// 'rotate-[143deg]'
// 'rotate-[144deg]'
// 'rotate-[145deg]'
// 'rotate-[146deg]'
// 'rotate-[147deg]'
// 'rotate-[148deg]'
// 'rotate-[149deg]'
// 'rotate-[150deg]'
// 'rotate-[151deg]'
// 'rotate-[152deg]'
// 'rotate-[153deg]'
// 'rotate-[154deg]'
// 'rotate-[155deg]'
// 'rotate-[156deg]'
// 'rotate-[157deg]'
// 'rotate-[158deg]'
// 'rotate-[159deg]'
// 'rotate-[160deg]'
// 'rotate-[161deg]'
// 'rotate-[162deg]'
// 'rotate-[163deg]'
// 'rotate-[164deg]'
// 'rotate-[165deg]'
// 'rotate-[166deg]'
// 'rotate-[167deg]'
// 'rotate-[168deg]'
// 'rotate-[169deg]'
// 'rotate-[170deg]'
// 'rotate-[171deg]'
// 'rotate-[172deg]'
// 'rotate-[173deg]'
// 'rotate-[174deg]'
// 'rotate-[175deg]'
// 'rotate-[176deg]'
// 'rotate-[177deg]'
// 'rotate-[178deg]'
// 'rotate-[179deg]'
// 'rotate-[180deg]'
// 'rotate-[181deg]'
// 'rotate-[182deg]'
// 'rotate-[183deg]'
// 'rotate-[184deg]'
// 'rotate-[185deg]'
// 'rotate-[186deg]'
// 'rotate-[187deg]'
// 'rotate-[188deg]'
// 'rotate-[189deg]'
// 'rotate-[190deg]'
// 'rotate-[191deg]'
// 'rotate-[192deg]'
// 'rotate-[193deg]'
// 'rotate-[194deg]'
// 'rotate-[195deg]'
// 'rotate-[196deg]'
// 'rotate-[197deg]'
// 'rotate-[198deg]'
// 'rotate-[199deg]'
// 'rotate-[200deg]'
// 'rotate-[201deg]'
// 'rotate-[202deg]'
// 'rotate-[203deg]'
// 'rotate-[204deg]'
// 'rotate-[205deg]'
// 'rotate-[206deg]'
// 'rotate-[207deg]'
// 'rotate-[208deg]'
// 'rotate-[209deg]'
// 'rotate-[210deg]'
// 'rotate-[211deg]'
// 'rotate-[212deg]'
// 'rotate-[213deg]'
// 'rotate-[214deg]'
// 'rotate-[215deg]'
// 'rotate-[216deg]'
// 'rotate-[217deg]'
// 'rotate-[218deg]'
// 'rotate-[219deg]'
// 'rotate-[220deg]'
// 'rotate-[221deg]'
// 'rotate-[222deg]'
// 'rotate-[223deg]'
// 'rotate-[224deg]'
// 'rotate-[225deg]'
// 'rotate-[226deg]'
// 'rotate-[227deg]'
// 'rotate-[228deg]'
// 'rotate-[229deg]'
// 'rotate-[230deg]'
// 'rotate-[231deg]'
// 'rotate-[232deg]'
// 'rotate-[233deg]'
// 'rotate-[234deg]'
// 'rotate-[235deg]'
// 'rotate-[236deg]'
// 'rotate-[237deg]'
// 'rotate-[238deg]'
// 'rotate-[239deg]'
// 'rotate-[240deg]'
// 'rotate-[241deg]'
// 'rotate-[242deg]'
// 'rotate-[243deg]'
// 'rotate-[244deg]'
// 'rotate-[245deg]'
// 'rotate-[246deg]'
// 'rotate-[247deg]'
// 'rotate-[248deg]'
// 'rotate-[249deg]'
// 'rotate-[250deg]'
// 'rotate-[251deg]'
// 'rotate-[252deg]'
// 'rotate-[253deg]'
// 'rotate-[254deg]'
// 'rotate-[255deg]'
// 'rotate-[256deg]'
// 'rotate-[257deg]'
// 'rotate-[258deg]'
// 'rotate-[259deg]'
// 'rotate-[260deg]'
// 'rotate-[261deg]'
// 'rotate-[262deg]'
// 'rotate-[263deg]'
// 'rotate-[264deg]'
// 'rotate-[265deg]'
// 'rotate-[266deg]'
// 'rotate-[267deg]'
// 'rotate-[268deg]'
// 'rotate-[269deg]'
// 'rotate-[270deg]'
// 'rotate-[271deg]'
// 'rotate-[272deg]'
// 'rotate-[273deg]'
// 'rotate-[274deg]'
// 'rotate-[275deg]'
// 'rotate-[276deg]'
// 'rotate-[277deg]'
// 'rotate-[278deg]'
// 'rotate-[279deg]'
// 'rotate-[280deg]'
// 'rotate-[281deg]'
// 'rotate-[282deg]'
// 'rotate-[283deg]'
// 'rotate-[284deg]'
// 'rotate-[285deg]'
// 'rotate-[286deg]'
// 'rotate-[287deg]'
// 'rotate-[288deg]'
// 'rotate-[289deg]'
// 'rotate-[290deg]'
// 'rotate-[291deg]'
// 'rotate-[292deg]'
// 'rotate-[293deg]'
// 'rotate-[294deg]'
// 'rotate-[295deg]'
// 'rotate-[296deg]'
// 'rotate-[297deg]'
// 'rotate-[298deg]'
// 'rotate-[299deg]'
// 'rotate-[300deg]'
// 'rotate-[301deg]'
// 'rotate-[302deg]'
// 'rotate-[303deg]'
// 'rotate-[304deg]'
// 'rotate-[305deg]'
// 'rotate-[306deg]'
// 'rotate-[307deg]'
// 'rotate-[308deg]'
// 'rotate-[309deg]'
// 'rotate-[310deg]'
// 'rotate-[311deg]'
// 'rotate-[312deg]'
// 'rotate-[313deg]'
// 'rotate-[314deg]'
// 'rotate-[315deg]'
// 'rotate-[316deg]'
// 'rotate-[317deg]'
// 'rotate-[318deg]'
// 'rotate-[319deg]'
// 'rotate-[320deg]'
// 'rotate-[321deg]'
// 'rotate-[322deg]'
// 'rotate-[323deg]'
// 'rotate-[324deg]'
// 'rotate-[325deg]'
// 'rotate-[326deg]'
// 'rotate-[327deg]'
// 'rotate-[328deg]'
// 'rotate-[329deg]'
// 'rotate-[330deg]'
// 'rotate-[331deg]'
// 'rotate-[332deg]'
// 'rotate-[333deg]'
// 'rotate-[334deg]'
// 'rotate-[335deg]'
// 'rotate-[336deg]'
// 'rotate-[337deg]'
// 'rotate-[338deg]'
// 'rotate-[339deg]'
// 'rotate-[340deg]'
// 'rotate-[341deg]'
// 'rotate-[342deg]'
// 'rotate-[343deg]'
// 'rotate-[344deg]'
// 'rotate-[345deg]'
// 'rotate-[346deg]'
// 'rotate-[347deg]'
// 'rotate-[348deg]'
// 'rotate-[349deg]'
// 'rotate-[350deg]'
// 'rotate-[351deg]'
// 'rotate-[352deg]'
// 'rotate-[353deg]'
// 'rotate-[354deg]'
// 'rotate-[355deg]'
// 'rotate-[356deg]'
// 'rotate-[357deg]'
// 'rotate-[358deg]'
// 'rotate-[359deg]'
