import { FunctionComponent } from 'react';
import PikButton from '../../../base_components/pik/PikButton/PikButton';
import PikText from '../../../base_components/pik/PikText/PikText';
import getStaticImageUrl from '../../../utils/getStaticImageUrl';
import { useForceNavigate } from '../../../utils/hooks';
import { unreachable } from '../../../utils/unreachable';
import ErrorPageType from '../types/ErrorPageType';

type Props = {
  errorType: ErrorPageType;
  canGoHome?: boolean;
  canGoHomeText?: string;
};

const ErrorPage: FunctionComponent<Props> = ({
  errorType,
  canGoHome = true,
  canGoHomeText = 'Take me home',
}) => {
  const { forceNavigate } = useForceNavigate({});

  return (
    <div className='h-screen w-screen flex flex-col justify-center items-center bg-pik-white p-[32px] sm:p-[64px]'>
      <div className='flex flex-col items-center text-center space-y-8'>
        {getImage(errorType)}
        <div>
          <PikText size='m' weight='medium'>
            {(() => {
              switch (errorType) {
                case '404':
                  return 'The page you are looking for either does not exist or you do not have access to it.';
                case 'generic':
                  return 'Something went wrong.';
                case 'removed-from-app':
                  return 'You have been removed from this application.';
                default:
                  return unreachable(errorType);
              }
            })()}
          </PikText>
          {errorType !== 'removed-from-app' && (
            <PikText size='s' color='neutral-500'>
              {'If this issue persists, email support@getcobblestone.com for help'}
            </PikText>
          )}
        </div>
        {canGoHome ? (
          <PikButton
            variant='purple'
            size='medium'
            onClick={() => {
              forceNavigate('/', {
                replace: true,
              });
            }}
          >
            {canGoHomeText}
          </PikButton>
        ) : (
          <PikButton
            variant='white'
            size='medium'
            onClick={() => {
              forceNavigate('/logout', {
                replace: true,
              });
            }}
          >
            Log Out
          </PikButton>
        )}
      </div>
    </div>
  );
};

const getImage = (errorType: ErrorPageType) => {
  switch (errorType) {
    case '404':
    case 'removed-from-app':
      return (
        <div className='w-64 h-28 overflow-hidden inline-block'>
          <img
            src={getStaticImageUrl({ id: '18fbe384-5e63-47eb-6e8b-15717f6efa00', variant: 'public' })}
            className='object-cover w-full h-full'
            alt=''
          />
        </div>
      );
    case 'generic':
      return (
        <div className='w-60 h-44 overflow-hidden inline-block'>
          <img
            src={getStaticImageUrl({ id: '72424434-a8db-48ba-0089-e66b6c946d00', variant: 'public' })}
            className='object-cover w-full h-full'
            alt=''
          />
        </div>
      );
    default:
      return unreachable(errorType);
  }
};

export default ErrorPage;
