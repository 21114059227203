import { useState } from 'react';

const useLocalStorage: (
  keyName: string,
  defaultValue: string | null
) => [string | null, (newValue: string | null) => void] = (
  keyName: string,
  defaultValue: string | null
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);

      if (value !== null) {
        return value;
      } else {
        if (defaultValue !== null) {
          localStorage.setItem(keyName, defaultValue);
        }
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: string | null) => {
    if (newValue === null) {
      localStorage.removeItem(keyName);
    } else {
      localStorage.setItem(keyName, newValue);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
