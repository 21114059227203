import {
  ChangeEvent,
  ChangeEventHandler,
  FunctionComponent,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { classNames } from '../../../utils/classNames';
import { PikIconSource } from '../PikIcon/PikIconSources';
import PikIcon from '../PikIcon/PikIcon';
import PikIconXRound from '../PikIcon/icons/XRound/PikIconXRound';
import getTextStylesForSize from '../PikText/utils/getTextStylesForSize';
import PikInputSize from './types/PikInputSize';
import HTMLInputMode from './types/HTMLInputMode';
import PikTooltip from '../PikTooltip/PikTooltip';
import PikInputRef from './types/PikInputRef';

export type PikInputProps = {
  value: string;
  /* 
  rawEvent is included when the change is triggered by the user typing,
  it is not included when the javascript triggers the change 
  (e.g. the clear button was clicked)
  */
  onChange: (value: string, rawEvent?: ChangeEvent<HTMLInputElement>) => void;
  size: PikInputSize;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  helpText?: string;
  leftIconSource?: PikIconSource;
  showClearIcon?: boolean;
  showLabel?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  type?: 'text' | 'number' | 'email' | 'password';
  inputRef?: Ref<PikInputRef | null>;
  labelIconData?: {
    iconSource: PikIconSource;
    iconTooltipContent?: string;
  };
  // We'll likely want more in the future https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autoComplete?: 'email' | 'one-time-code' | 'address-line1' | 'address-line2' | 'address-level1';
  textAlign?: 'left' | 'center' | 'right';
  onEnter?: () => void;
  onEmptyBackspace?: () => void;
  hideAllLabelSpaces?: boolean;
  hideUpperLabelSpaces?: boolean;
  hideLowerLabelSpaces?: boolean;
  useAbsoluteError?: boolean;
  sentryMask?: boolean;
};

const PikInput: FunctionComponent<PikInputProps> = ({
  value,
  onChange,
  size,
  label,
  placeholder,
  disabled,
  errorMessage,
  helpText,
  leftIconSource,
  showClearIcon,
  showLabel = false,
  onFocus,
  onBlur,
  type,
  inputRef,
  labelIconData,
  autoComplete,
  onEnter,
  onEmptyBackspace,
  textAlign,
  hideAllLabelSpaces = false,
  hideUpperLabelSpaces = false,
  useAbsoluteError = false,
  sentryMask = false,
}) => {
  const isEmpty = value === '';
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const innerRef = useRef<HTMLInputElement>(null);
  // When true, shows the red error box around the component until
  // a change or blur
  const [errorHighlight, setErrorHighlight] = useState<boolean>(false);
  useImperativeHandle(
    inputRef,
    () => {
      return {
        highlightError: () => {
          setErrorHighlight(true);
        },
        removeHighlightError: () => {
          setErrorHighlight(false);
        },
        setSelectionRange: (...args) => {
          innerRef.current?.setSelectionRange(...args);
        },
        focus: (...args) => {
          innerRef.current?.focus(...args);
        },
        blur: (...args) => {
          innerRef.current?.blur(...args);
        },
        target: {
          value: innerRef.current?.value,
        },
      };
    },
    [inputRef, innerRef, setErrorHighlight]
  );

  let innerOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (errorHighlight) {
      setErrorHighlight(false);
    }
    onChange(event.target.value, event);
  };

  let onKeyDown: React.KeyboardEventHandler<HTMLInputElement> | undefined = undefined;
  if (onEnter !== undefined || onEmptyBackspace !== undefined) {
    onKeyDown = (event) => {
      if (event.key === 'Enter') {
        onEnter && onEnter();
      } else if (event.key === 'Backspace' && isEmpty) {
        onEmptyBackspace && onEmptyBackspace();
      }
    };
  }

  let pattern;
  let inputMode: HTMLInputMode;
  let inputType = type;
  if (type === 'number') {
    pattern = '[0-9]*';
    inputMode = 'numeric';

    // We don't actually want to use the number input type,
    // because we lost control of things like formatting characters
    inputType = 'text';
  } else if (type === 'email') {
    inputType = 'email';
    innerOnChange = (event) => {
      if (errorHighlight) {
        setErrorHighlight(false);
      }
      onChange(event.target.value.trim(), event);
    };

    // https://github.com/facebook/react/issues/6368
    // Chrome does weird things to the email type that cause
    // The react state to not reflect what the user sees.
    // We can preventDefault when we detect a space like the workaround in that issue
    // https://github.com/facebook/react/issues/6368#issuecomment-468751062
    const newOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (onEnter !== undefined) {
        if (event.key === 'Enter') {
          onEnter();
        }
      }

      if (event.key === ' ') {
        event.preventDefault();
      }
    };

    onKeyDown = newOnKeyDown;
  }

  const inputSizeClassNames = ['w-full'];
  const wrapperSizeClassNames = ['w-full'];
  if (size === 'large') {
    inputSizeClassNames.push(getTextStylesForSize('16'));
    wrapperSizeClassNames.push(getTextStylesForSize('16'));
    if (isFocused) {
      // The border gets a bit thicker when focuses, so we reduce padding
      // so the whole element size doesn't change
      wrapperSizeClassNames.push('py-[10.5px] px-[12.5px]');
    } else {
      wrapperSizeClassNames.push('py-[11px] px-[13px]');
    }
  } else if (size === 'medium') {
    inputSizeClassNames.push(getTextStylesForSize('14'));
    wrapperSizeClassNames.push(getTextStylesForSize('14'));
    if (isFocused) {
      wrapperSizeClassNames.push('py-[8px] px-[12.5px]');
    } else {
      wrapperSizeClassNames.push('py-[8.5px] px-[13px]');
    }
  }

  let inputBackgroundClassName = 'bg-pik-white';
  let inputCursorClassName = '';
  const inputVisualClassNames =
    'flex grow focus-visible:outline-none text-inherit placeholder:text-pik-neutral-400 border-none p-0 focus:ring-0 focus:shadow-none focus:border-none';
  let inputTextAlignClassName = '';
  let wrapperBackgroundClassName = 'bg-pik-white';
  let wrapperTextColorClassName = '';
  let wrapperBorderSizeClassNames = 'border-[1px]';
  let wrapperBorderColorClassNames = 'border-pik-neutral-200';
  let wrapperBorderHoverClassNames = 'hover:border-pik-neutral-600';
  let wrapperCursorClassName = '';
  const wrapperRoundedClassName = 'rounded-[2px]';

  if (isEmpty) {
    wrapperTextColorClassName = 'text-pik-neutral-400';
  } else {
    wrapperTextColorClassName = 'text-pik-slate-I';
  }

  if (isFocused) {
    inputBackgroundClassName = 'bg-pik-neutral-25';
    wrapperBackgroundClassName = 'bg-pik-neutral-25';
    wrapperBorderSizeClassNames = 'border-[1.5px]';
    wrapperBorderColorClassNames = 'border-pik-neutral-600';
  }

  if (leftIconSource !== undefined || showClearIcon === true) {
    wrapperSizeClassNames.push('flex flex-row items-center space-x-[8px]');
  }

  if (textAlign === 'left') {
    inputTextAlignClassName = 'text-left';
  } else if (textAlign === 'center') {
    inputTextAlignClassName = 'text-center';
  } else if (textAlign === 'right') {
    inputTextAlignClassName = 'text-right';
  }

  if (disabled) {
    inputCursorClassName = 'cursor-not-allowed';
    wrapperCursorClassName = ' cursor-not-allowed';
    wrapperBorderColorClassNames = 'border-pik-neutral-100';
    wrapperBorderHoverClassNames = 'hover:border-pik-neutral-100';
    inputBackgroundClassName = 'bg-pik-neutral-50';
    wrapperBackgroundClassName = 'bg-pik-neutral-50';
  }

  let bottomText = '';
  const bottomTextClassNames = [`w-full h-[16.8px] mt-[6px] ${getTextStylesForSize('12')}`];
  if (errorMessage !== undefined) {
    bottomText = errorMessage;
    bottomTextClassNames.push('text-pik-terracotta');
    wrapperBorderColorClassNames = 'border-pik-terracotta';
    wrapperBorderHoverClassNames = 'hover:border-pik-terracotta';
  } else {
    if (helpText !== undefined) {
      bottomText = helpText;
      bottomTextClassNames.push('text-pik-neutral-500');
    }

    if (errorHighlight) {
      wrapperBorderColorClassNames = 'border-pik-terracotta';
      wrapperBorderHoverClassNames = 'hover:border-pik-terracotta';
    }
  }

  let labelIconContent = undefined;
  if (labelIconData !== undefined) {
    const labelIcon = <PikIcon iconSource={labelIconData.iconSource} size='small' color='inherit' />;

    if (labelIconData.iconTooltipContent !== undefined) {
      labelIconContent = (
        <PikTooltip content={labelIconData.iconTooltipContent} direction='top-right'>
          {labelIcon}
        </PikTooltip>
      );
    } else {
      labelIconContent = labelIcon;
    }
  }

  return (
    <div className='relative w-full flex flex-col items-start text-left'>
      {!hideAllLabelSpaces && !hideUpperLabelSpaces && (
        <>
          {showLabel ? (
            <div className='mb-[6px] flex flex-row-reverse items-center space-x-[2px] text-pik-slate-I'>
              <p className={` ${getTextStylesForSize('13')}`}>{label}</p>
              {labelIconContent}
            </div>
          ) : (
            <div className='w-[1px] h-[18.2px] mb-[6px]' />
          )}
        </>
      )}
      <div
        className={classNames(
          wrapperSizeClassNames.join(' '),
          wrapperRoundedClassName,
          wrapperBackgroundClassName,
          wrapperTextColorClassName,
          wrapperBorderSizeClassNames,
          wrapperBorderColorClassNames,
          wrapperBorderHoverClassNames,
          wrapperCursorClassName
        )}
      >
        {leftIconSource !== undefined && (
          <div className='flex shrink-0'>
            <PikIcon iconSource={leftIconSource} color='inherit' />
          </div>
        )}
        <input
          ref={innerRef}
          aria-label={label}
          autoComplete={autoComplete}
          value={value}
          onChange={innerOnChange}
          placeholder={placeholder}
          className={classNames(
            inputSizeClassNames.join(' '),
            inputVisualClassNames,
            inputBackgroundClassName,
            inputCursorClassName,
            inputTextAlignClassName
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onFocus={(e) => {
            setIsFocused(true);
            if (onFocus !== undefined) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            setIsFocused(false);
            if (errorHighlight) {
              setErrorHighlight(false);
            }
            if (onBlur !== undefined) {
              onBlur(e);
            }
          }}
          disabled={disabled}
          type={inputType}
          pattern={pattern}
          inputMode={inputMode}
          onKeyDown={onKeyDown}
          {...(sentryMask ? { 'data-sentry-mask': true } : {})}
        />
        {showClearIcon &&
          (isEmpty ? (
            <div className='w-[20px] h-[20px] flex shrink-0' />
          ) : (
            <button
              aria-label={`Clear ${label} search`}
              className='flex shrink-0 cursor-pointer'
              onClick={() => {
                onChange('', undefined);
              }}
            >
              <PikIcon iconSource={PikIconXRound} color='inherit' />
            </button>
          ))}
      </div>
      {!hideAllLabelSpaces && (
        <p
          className={classNames(
            bottomTextClassNames.join(' '),
            useAbsoluteError ? 'absolute bottom-[-17px] left-[0px]' : ''
          )}
        >
          {bottomText}
        </p>
      )}
    </div>
  );
};

export default PikInput;
