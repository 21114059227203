import { createContext, FunctionComponent, ReactNode, useContext } from 'react';
import { IDENTITY_STORAGE_KEY, JWT_STORAGE_KEY, WEAK_JWT_STORAGE_KEY } from '../../api/auth';
import useLocalStorage from '../../hooks/useLocalStorage';
import AuthObject, { SaveJwtAndIdentityIdArgs } from './AuthObject';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext<AuthObject | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [jwt, setJwt] = useLocalStorage(JWT_STORAGE_KEY, null);
  const [identityId, setIdentityId] = useLocalStorage(IDENTITY_STORAGE_KEY, null);
  const [isWeak, setIsWeak] = useLocalStorage(WEAK_JWT_STORAGE_KEY, 'false');
  const queryClient = useQueryClient();

  const saveJwtAndIdentityId = ({ jwt, identityId, isWeak = false }: SaveJwtAndIdentityIdArgs) => {
    setJwt(jwt);
    setIdentityId(identityId);
    setIsWeak(isWeak.toString());
  };

  const logout = () => {
    setJwt(null);
    setIdentityId(null);
    setIsWeak('false');
    queryClient.clear();
  };

  return (
    <AuthContext.Provider
      value={{ saveJwtAndIdentityId, logout, identityId, jwt, isWeak: isWeak === 'true' }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hooked to have a fully typed AuthContext
export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) throw new Error('No AuthContext.Provider found when calling useAuthContext.');
  return authContext;
};
